import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Import des icônes Font Awesome

const Pagination = ({ currentPage, totalItems, itemsPerPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        {/* Icône pour "Précédent" */}
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button 
            className="page-link" 
            onClick={() => onPageChange(currentPage - 1)}
            aria-label="Précédent"
          >
            <FaArrowLeft /> {/* Icône de la flèche gauche */}
          </button>
        </li>

        {/* Numéros de page */}
        {pages.map((page) => (
          <li key={page} className={`page-item ${currentPage === page ? "active" : ""}`}>
            <button className="page-link" onClick={() => onPageChange(page)}>
              {page}
            </button>
          </li>
        ))}

        {/* Icône pour "Suivant" */}
        <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
          <button 
            className="page-link" 
            onClick={() => onPageChange(currentPage + 1)}
            aria-label="Suivant"
          >
            <FaArrowRight /> {/* Icône de la flèche droite */}
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
