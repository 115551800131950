export const URLS = {
  //BASE_URL: "http://localhost:8000/api/",
  BASE_URL: "https://api.goliat.fr/api/",
  //CLIENT_ID: "rgFz0scCkvH2qAm4iBWwk5m8f6qC9SVM5RHzrKze", glpat-bKxC_QSX5-xc5PggNv82
  AUTHS: {
    LOGIN: "auth/token/",
    REFRESH: "auth/token/refresh/",
    LOGOUT: "auth/logout/",
  },
  PASSWORD: {
      reset: "password-reset/",
      confirm: "password-reset-confirm/<uidb64>/<token>/",
      change: "password/me/change/",
    },
  USERS: {
      create: "users/",
      list: (page = 1, limit = 10) => `users/?page=${page}&limit=${limit}`, // Pagination dynamique
      update: (id) => `users/${id}/`,
      delete: (id) => `users/${id}/`,
  },
  PROFIL: {
    update: 'profile/update/',
    me: 'profile/me/'
  },
  CHATGPT: {
    chat: {
      createOrGetChat: 'chatgpt/chat/', // créer une nouvelle discussion
      details: 'chatgpt/list/<int:chat_id>/messages/', //récupère tous les messages de la discussion dans laquelle on entre
      update: 'chatgpt/chat/<int:chat_id>/', // mets à jour la discussion dans laquelle on est en ajoutant des questions réponses
      delete: (chat_id) => `chatgpt/chat/${chat_id}/delete/`, // supprimer une discussion
    },
    USERDISCUSS: {
      list: 'chatgpt/chat/user/discussions/'
    }, // Pour récupérer les discussions de l'utilisateur connecté
    SUMMARY: {
      list: 'chatgpt/chat/<int:chat_id>/context/'
    }
  },

};
