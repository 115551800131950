import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../services/axios";
import { URLS } from "../../../services/urls";
import { getUserDetails, setUserDetails } from "../../../components/utils/functions";
import { toast } from "react-toastify";
import { updateUserData } from "../../../store/slices/auth";
import { DEFAULT_PROFILE } from "../../../components/utils/consts";
import { FaPaperclip, FaSignOutAlt } from "react-icons/fa";
import { logout } from "../../../services/logout";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Preloader from "../../../components/preloader/Preloader";


const MyProfile = () => {
  const { t } = useTranslation();
  const [profile_image, setProfileImage] = useState();
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(DEFAULT_PROFILE);  // Default profile image
  const { auth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const { register: registerPassword, handleSubmit: handlePasswordSubmit, reset: resetPassword } = useForm(); // Pour le changement de mot de passe
  const [user, setUser] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  useEffect(() => {
    const userDetails = getUserDetails();
    if (userDetails) {
      setUser(userDetails);
    }
  }, []);
  
  useEffect(() => {
    // Fetch the profile data of the connected user
    setLoading(true);
    API.get(URLS.PROFIL.me)
      .then((resp) => {
        const { data } = resp;
        reset({
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          username: data.username,
          service: data.service,
          role: data.role,
          bio: data.bio,
        });
        setLoading(false);
        setPreview(data.profile_image || DEFAULT_PROFILE);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Failed to load user data.");
      });
  }, [auth, reset]);

  const handleChangeProfile = (event) => {
    const file = event?.target?.files[0];
    if (file) {
      setProfileImage(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const onSubmit = (data) => {
    setLoading(true);
    
    let formData = new FormData();
    formData.append("first_name", data.firstName);
    formData.append("last_name", data.lastName);
    formData.append("email", data.email);
    formData.append("username", data.username);
    formData.append("service", data.service);
    formData.append("role", data.role);
    formData.append("bio", data.bio);

    if (profile_image) {
      formData.append("profile_image", profile_image);
    }

    API.patch(URLS.PROFIL.update, formData)
      .then((resp) => {
        const { data } = resp;
        const user = getUserDetails();
        setUserDetails({
          ...user,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          username: data.username ?? "",
          service: data.service ?? "",
          role: data.role ?? "",
          bio: data.bio ?? "",
          profile_image: data.profile_image ?? user?.profile_image,
        });
        setLoading(false);
        toast.success("Profile updated successfully!");
        dispatch(updateUserData({ ...data, groups: user.groups }));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail || "Failed to update profile.");
        setLoading(false);
      });
  };

  const onSubmitPasswordChange = (data) => {
    setLoading(true);
  
    API.post(URLS.PASSWORD.change, {
      old_password: data.oldPassword,
      new_password: data.newPassword,
    })
      .then(() => {
        toast.success("Password changed successfully!");
  
        // Appeler la fonction de déconnexion après le changement de mot de passe
        logout(t);  // Déconnecter l'utilisateur
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail || "Failed to change password.");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
    <div className="chat-header">
      <div className="profile-dropdown">
        <img 
          src={"/assets/img/logo_goliat.png"} 
          alt="User Profile" 
          className="profile-image" 
          onClick={toggleDropdown} 
        />
        {isDropdownOpen && (
          <div className="dropdown-menu">
            <Link className='dropdown-item' to='/my/profil'>Mon profil</Link>
            <Link className='dropdown-item' to='/'>Tableau de bord</Link>
            <Link className='dropdown-item text-danger' to="#" onClick={() => logout(t)}>Déconnecter <FaSignOutAlt size={20} style={{ marginRight: '8px' }}/></Link>
          </div>
        )}
      </div>
    </div>

    <div className="profile-container">
      <h2>Mon Profil</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="profile-form">
        {/* Profil Image Upload Section */}
        <div className="form-group">
          <label>Image de profil</label>
          <div className="profile-image-wrapper">
            <img src={preview} alt="Profile Preview" className="profile-image" />
            <label htmlFor="upload-input" className="upload-btn">
              <FaPaperclip />
            </label>
            <input id="upload-input" type="file" onChange={handleChangeProfile} style={{ display: 'none' }} />
          </div>
        </div>
        {/* Profile fields */}
        <div className="form-group">
          <label>Prénom</label>
          <input type="text" {...register("firstName")} />
        </div>
        <div className="form-group">
          <label>Nom</label>
          <input type="text" {...register("lastName")} />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input type="email" {...register("email")} />
        </div>
        <div className="form-group">
          <label>Surnom</label>
          <input type="text" {...register("username")} />
        </div>
        <div className="form-group">
          <label>Service</label>
          <input type="text" {...register("service")} readOnly />
        </div>
        <div className="form-group">
          <label>Role</label>
          <input type="text" {...register("role")} readOnly />
        </div>
        <div className="form-group">
          <label>Biographie</label>
          <textarea {...register("bio")} rows="4" />
        </div>
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? "Updating..." : "Mettre à jour"}
        </button>
      </form>
    </div>
    <div className="password-container">
     {/* Password change section */}
     <div className="password-change-section">
        <h3>Changer le mot de passe</h3>
        <form className="profile-form" onSubmit={handlePasswordSubmit(onSubmitPasswordChange)}>
          <div className="form-group">
            <label>Ancien mot de passe</label>
            <input type="password" {...registerPassword("oldPassword")} />
          </div>
          <div className="form-group">
            <label>Nouveau mot de passe</label>
            <input type="password" {...registerPassword("newPassword")} />
          </div>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? "Updating..." : "Changer le mot de passe"}
          </button>
        </form>
      </div>
      </div>
    </>
  );
};

export default MyProfile;
