import { URLS } from "../../services/urls";


let ls = {}

ls.set = window.localStorage.setItem.bind(localStorage);
ls.get = window.localStorage.getItem.bind(localStorage);

const getUserDetails = () => {
  const userDetails = window.localStorage.getItem("user-details");
  return userDetails ? JSON.parse(userDetails) : null; // Toujours vérifier si les détails existent
};

const isAdmin = () => {
  const user = getUserDetails();
  return user && user.role === 'admin'; // Vérification directe sur user.role
};

const isManager = () => {
  const user = getUserDetails();
  return user && user.role === 'manager'; // Vérification directe sur user.role
};

const hasRole = (role) => {
  const user = getUserDetails();
  return user && user.role === role; // Vérification directe sur user.role
};



const setUserDetails = (details) => window.localStorage.setItem("user-details", JSON.stringify(details));

const appScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
}

const formatLinkString = (link) => {
  if(link?.includes("https://")){
    return link;
  } else {
    return URLS.BASE_URL + link;
  }
}

const percentageFormater = (number)=>{
  const formater = new Intl.NumberFormat("EN",{
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return number>1?formater.format(number/100):formater.format(number);
}
export {ls, setUserDetails, getUserDetails, appScrollTo, formatLinkString, percentageFormater, isAdmin, isManager, hasRole}