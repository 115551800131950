import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth: JSON.parse(localStorage.getItem('auth')) || null, // Initialiser avec les données stockées dans localStorage
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.auth = action.payload;
      localStorage.setItem('auth', JSON.stringify(action.payload)); // Sauvegarde des données utilisateur
    },
    logout: (state) => {
      state.auth = null;
      localStorage.removeItem('auth'); // Suppression des données utilisateur
    },
    updateUserData: (state, action) => {
      
      if(state.auth){
        state.auth.user = action.payload;
      }
    },
  },
});

export const { loginSuccess, logout, updateUserData } = authSlice.actions;

export default authSlice.reducer;
