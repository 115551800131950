import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { API } from '../../services/axios';
import { URLS } from '../../services/urls';
import { toast } from 'react-toastify';
import ReactMarkdown from 'react-markdown';
import Preloader from '../../components/preloader/Preloader';
import { FaPaperPlane, FaTimes, FaPaperclip, FaSignOutAlt, FaCopy, FaDownload } from 'react-icons/fa';  
import { getUserDetails } from '../../components/utils/functions';
import { logout } from '../../services/logout';
import { useTranslation } from "react-i18next";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ChatWindow = () => {
  const { t } = useTranslation();
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [csvFiles, setCsvFiles] = useState([]);
  const [excelFiles, setExcelFiles] = useState([]);
  const [memorySummary, setMemorySummary] = useState('');

  useEffect(() => {
    const userDetails = getUserDetails();
    if (userDetails) {
      setUser(userDetails);
    }
  }, []);

  useEffect(() => {
    const user = getUserDetails();
    if (user && user.email) {
      setUserEmail(user.email);
    }
    const fetchMessages = async () => {
      setLoading(true);
      try {
        const response = await API.get(`${URLS.BASE_URL}${URLS.CHATGPT.chat.details.replace('<int:chat_id>', chatId)}`);
        if (Array.isArray(response.data)) {
          setMessages(response.data);
        } else {
          toast.error('Les données reçues ne sont pas valides.');
        }
      } catch (error) {
        toast.error('Erreur lors de la récupération des messages.');
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [chatId]);

  useEffect(() => {
    const imagePreviews = images.map(image => URL.createObjectURL(image));
    const pdfPreviews = pdfFiles.map(pdf => pdf.name);
    const csvPreviews = csvFiles.map(csv => csv.name);
    const excelPreviews = excelFiles.map(excel => excel.name);

    setPreviews([...imagePreviews, ...pdfPreviews, ...csvPreviews, ...excelPreviews]);
    return () => {
      imagePreviews.forEach(url => URL.revokeObjectURL(url));
    };
  }, [images, pdfFiles, csvFiles, excelFiles]);

  const handleSendMessage = async () => {
    if (!newMessage.trim() && images.length === 0 && pdfFiles.length === 0 && csvFiles.length === 0 && excelFiles.length === 0) {
      toast.error("Veuillez taper un message ou ajouter des fichiers avant d'envoyer.");
      return;
    }

    const newMessageObject = {
      role: 'user',
      content: newMessage,
    };

    setMessages((prevMessages) => [...prevMessages, newMessageObject]);

    try {
      const formData = new FormData();
      formData.append('role', 'user');
      formData.append('content', newMessage);

      images.forEach(image => formData.append('image', image));
      pdfFiles.forEach(pdf => formData.append('pdf', pdf));
      csvFiles.forEach(csv => formData.append('csv', csv));
      excelFiles.forEach(excel => formData.append('excel', excel));

      setLoading(true);
      const response = await API.post(
        `${URLS.BASE_URL}${URLS.CHATGPT.chat.update.replace('<int:chat_id>', chatId)}`,
        formData
      );

      const updatedMessages = response.data.messages;

      if (Array.isArray(updatedMessages)) {
        setMessages((prevMessages) => [...prevMessages, ...updatedMessages.filter(msg => msg.role !== 'user')]);
      } else {
        toast.error('Réponse du serveur non valide.');
      }

      setNewMessage('');
      setImages([]);
      setPdfFiles([]);
      setCsvFiles([]);
      setExcelFiles([]);
    } catch (error) {
      toast.error('Erreur lors de l\'envoi du message.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const newFiles = [...e.target.files];
    const imageFiles = newFiles.filter(file => file.type.startsWith('image/'));
    const pdfFiles = newFiles.filter(file => file.type === 'application/pdf');
    const csvFiles = newFiles.filter(file => file.type === 'text/csv');
    const excelFiles = newFiles.filter(file => 
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
      file.type === 'application/vnd.ms-excel'
    );
  
    setImages(imageFiles);
    setPdfFiles(pdfFiles);
    setCsvFiles(csvFiles);
    setExcelFiles(excelFiles);
  };

  const handleRemoveFile = (index, fileType) => {
    if (fileType === 'image') {
      setImages(images.filter((_, i) => i !== index));
    } else if (fileType === 'pdf') {
      setPdfFiles(pdfFiles.filter((_, i) => i !== index));
    } else if (fileType === 'csv') {
      setCsvFiles(csvFiles.filter((_, i) => i !== index));
    } else if (fileType === 'excel') {
      setExcelFiles(excelFiles.filter((_, i) => i !== index));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const scrollToBottom = () => {
      const messagesList = document.querySelector('.messages-list');
      if (messagesList) {
        messagesList.scrollTop = messagesList.scrollHeight;
      }
    };

    scrollToBottom();
  }, [messages]);

   const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Texte copié dans le presse-papiers !");
  };

  useEffect(() => {
    const fetchMemorySummary = async () => {
      try {
        const response = await API.get(`${URLS.BASE_URL}${URLS.CHATGPT.SUMMARY.list.replace('<int:chat_id>', chatId)}`);
        if (response.data.memory_summary) {
          setMemorySummary(response.data.memory_summary);
        } else {
          toast.error('Début de la synchronisation mémoire.');
        }
      } catch (error) {
        toast.error('Erreur lors de la récupération de la mémoire.');
      }
    };

    if (messages.length > 0) {
      fetchMemorySummary();
    }
  }, [messages, chatId]);

return (
  <div className="chat-window">
    <div className="chat-header">
      <div className="profile-dropdown">
        <img 
          src={"/assets/img/logo_goliat.png"} 
          alt="User Profile" 
          className="profile-image" 
          onClick={toggleDropdown} 
        />
        {isDropdownOpen && (
          <div className="dropdown-menu">
            <Link className='dropdown-item' to='/my/profil'>Mon profil</Link>
            <Link className='dropdown-item' to='/'>Tableau de bord</Link>
            <Link className='dropdown-item text-danger' to="#" onClick={() => logout(t)}>
              Se Déconnecter <FaSignOutAlt size={20} style={{ marginRight: '8px' }}/>
            </Link>
          </div>
        )}
      </div>
    </div>

    <div className="chat-body messages-list">
      {messages.map((message, index) => (
        <div 
          key={index} 
          className={message.role === 'user' ? 'user-message' : 'assistant-message'}
        >

{typeof message.content === 'string' && (
  <>
    {/* Vérifier si le message contient une URL d'image */}
    {message.content.includes("Image URL:") ? (
      <div className="image-message">
        {/* Extraire l'URL de l'image */}
        <img 
          src={message.content.split('Image URL: ')[1].split('\n')[0]}  // Extraire l'URL après 'Image URL:'
          alt={message.role === 'assistant' ? 'Image générée par l\'IA' : 'Image envoyée par l\'utilisateur'} 
          className={message.role === 'assistant' ? 'chat-image-assist' : 'chat-image'}
        />
        <a href={message.content.split('Image URL: ')[1].split('\n')[0]} download={`image_${index}.png`} className="download-link">
          <FaDownload className="download-icon" />
        </a>
        {/* Afficher la description si elle est présente */}
        {message.content.includes('Description:') && (
          <p className="image-description">
            {message.content.split('Description:')[1].trim()}  
          </p>
        )}
      </div>
    ) : (
      <ReactMarkdown
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <div className="code-block">
                <CopyToClipboard text={String(children).replace(/\n$/, '')}>
                  <button className="copy-btn" onClick={() => handleCopy(children)}>
                    <FaCopy />
                  </button>
                </CopyToClipboard>
                <SyntaxHighlighter
                  style={dracula}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              </div>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          }
        }}
      >
        {message.content}
      </ReactMarkdown>
    )}
  </>
)}

        </div>
      ))}
      {loading && (
        <div className="preloader-wrapper">
          <Preloader />
        </div>
      )}
    </div>

    <div className="chat-footer">
      <label htmlFor="upload-input" className="upload-btn">
        <FaPaperclip />
      </label>
      <input
        id="upload-input"
        type="file"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <input
        type="text"
        value={newMessage}
        onKeyDown={handleKeyDown}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Chat GOLIAT-IA"
      />
      <button 
        className="send-btn" 
        disabled={!newMessage.trim() && images.length === 0 && pdfFiles.length === 0 && csvFiles.length === 0 && excelFiles.length === 0} 
        onClick={handleSendMessage}
      >
        <FaPaperPlane />
      </button>
    </div>

    {previews.length > 0 && (
      <div className="image-preview">
        {previews.map((preview, index) => (
          <div key={index} className="image-preview">
            {preview.endsWith('.pdf') ? (
              <div className="image-preview">
                <p>{preview}</p>
                <button onClick={() => handleRemoveFile(index, 'pdf')}><FaTimes /></button>
              </div>
            ) : preview.endsWith('.csv') ? (
              <div className="image-preview">
                <p>{preview}</p>
                <button onClick={() => handleRemoveFile(index, 'csv')}><FaTimes /></button>
              </div>
            ) : preview.endsWith('.xlsx') ? (
              <div className="image-preview">
                <p>{preview}</p>
                <button onClick={() => handleRemoveFile(index, 'excel')}><FaTimes /></button>
              </div>
            ) : (
              <div className="image-preview">
                <img src={preview} alt="Preview" />
                <button onClick={() => handleRemoveFile(index, 'image')}><FaTimes /></button>
              </div>
            )}
          </div>
        ))}
      </div>
    )}
  </div>
);
};

export default ChatWindow;
