import React, { useState } from 'react';
import AdminList from '../users/admin/Index';
import { isAdmin, isManager } from '../../components/utils/functions';
import Quickmessage from '../chatpage/Quickmessage';
import { Link } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { logout } from '../../services/logout';
import { useTranslation } from "react-i18next";


const UserDashboard = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();




  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <div className='dashboard-container scroll-container'>
        <div className="chat-header">
          <div className="profile-dropdown">
              <img 
              // src={user?.profile_image || "/assets/img/logo_goliat.png"} 
                src={"/assets/img/logo_goliat.png"} 
                alt="User Profile" 
                className="profile-image" 
                onClick={toggleDropdown} 
              />
              {isDropdownOpen && (
                <div className="dropdown-menu">
                  <Link className='dropdown-item' to='/my/profil'>Mon profil</Link>
                  <Link className='dropdown-item' to='/'>Tableau de bord</Link>
                  <Link className='dropdown-item text-danger' to="#" onClick={() => logout(t)}>Déconnecter <FaSignOutAlt size={20} style={{ marginRight: '8px' }}/></Link>
                </div>
              )}
          </div>
    </div>
      {(isAdmin() || isManager()) && (
            <AdminList />
        )}
      <Quickmessage/>
      </div>
    </>
  );
};

export default UserDashboard;
