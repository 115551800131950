import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";

const ConfirmRecover = () => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { uuid, token } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({});
  const navigate = useNavigate();
  const onSubmit = (data) => {
    setLoading(true);

    const toSend = {
      new_password1: data.password,
      new_password2: data.confirmPassword,
      uid: uuid,
      token: token,
    };

    API.post(URLS.PASSWORD.confirm, toSend)
      .then((resp) => {
        setLoading(false);
        navigate("/login");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.detail ?? error?.response?.data?.message ?? t("textConfirmError"));
      });
  };
  return (
    <div className="box-content">
      <div className="box-heading"></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-box">
            <div className="container">
              <div className="panel-white mb-30 mt-100">
                <div className="box-padding">
                  <div className="login-register">
                    <div className="row login-register-cover pb-3">
                      <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
                        <div className="form-login-cover">
                          <div className="text-center">
                            <p className="font-sm text-brand-2">
                              {t("textSetNewPassword")}{" "}
                            </p>
                          </div>
                          <form
                            className="login-register text-start mt-20"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-4">
                                {t("password")} *
                              </label>
                              <input
                                className="form-control"
                                id="input-4"
                                type="password"
                                {...register("password", { required: true })}
                                placeholder="************"
                              />

                            </div>
                            <div className="form-group">
                              <label className="form-label" htmlFor="input-4">
                                {t("confirmPassword")} *
                              </label>
                              <input
                                className="form-control"
                                id="input-4"
                                type="password"
                                {...register("confirmPassword", {
                                  required: true,
                                  validate: (val) => {
                                    if (watch("password") !== val) {
                                      return t("textPwMustMatch");
                                    }
                                  },
                                })}
                                placeholder="************"
                              />
                              <span className="text-primary">
                                {errors.confirmPassword?.message && (
                                  <small>{errors.confirmPassword.message}</small>
                                )}
                              </span>
                            </div>
                            <div className="form-group">
                              <button
                                className="btn btn-brand-1 hover-up w-100"
                                type="submit"
                                name="login"
                                disabled={loading}
                              >
                                {t("textSetNewPassword")}
                                {loading && (
                                  <div
                                    className="spinner-border ml-10 spinner-border-sm"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                )}
                              </button>
                            </div>
                          </form>
                        </div>
                        <div className="img-2">
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRecover;
