import React from 'react';

const Preloader = () => {
  return (
    <div className="preloader-overlay">
      <div className="preloader">
        {/* Remplacez src par l'URL de votre propre image */}
        <img src="/assets/img/logo/images.png" alt="Loading" />
        <div className="spinner"></div>
      </div>
    </div>
  );
};

export default Preloader;
