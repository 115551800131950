import { Outlet } from "react-router-dom";
import PrivateRoute from "../../routes/components/PrivateRoute";
import Sidebar from "../sidebar/Sidebar";
import { useState } from "react";
import { FaBars } from "react-icons/fa"; // Icône pour le bouton toggle

const UserDashboardLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <PrivateRoute>
      <main>
      <div className={`app-container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <Outlet />
    </div>
      </main>
    </PrivateRoute>
  );
};

export default UserDashboardLayout;
