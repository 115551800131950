import AppRoutes from "./routes";
import { Provider } from "react-redux";
import { store } from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auth from "./components/auth";
import ConfigSWR from "./services/swr";

function App() {
  return (
    <Provider store={store}>
      <ConfigSWR>
        <Auth>
        <AppRoutes />
        </Auth>
        <ToastContainer />
      </ConfigSWR>
    </Provider>
  );
}

export default App;
