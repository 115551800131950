import moment from "moment";

export const formatDate = (date, pattern = "DD/MM/YYYY") =>
  date ? moment(date).format(pattern) : "";

export const splitTime = (time) => {
  if (!time) return null;

  const splited = time.split(":").map(Number);

  return {
    hour: splited[0],
    minute: splited[1],
  };
};

export const setDateTime = (
  date,
  time,
  pattern = "YYYY-MM-DDTHH:mm:ss.SSSSZ"
) => {
  const timeRefracted = splitTime(time);
  return moment(date || new Date())
    .set(timeRefracted)
    .format(pattern);
};

export const isCurrentMonth = (date) => {
  if (!Boolean(date)) return false;
  if (new Date(date).getFullYear() !== new Date().getFullYear()) return false;
  return new Date(date).getMonth() === new Date().getMonth();
};
